export const EVENTS_COLLECTION = "Events";

export const DATES_COLLECTION = "Dates";

export const BOOKINGS_COLLECTION = "Bookings";

export const ORGANISATIONS_COLLECTION = "Organisations";

export const MEMBERS_COLLECTION = "PersonalData";

export const INTERACTIONS_COLLECTION = "Interactions";

export const PROFILE_COLLECTION = "Profiles";

export const PROGRAMMES_COLLECTION = "FunderProgrammes";

export const ORDERS_COLLECTION = "Orders";

export const USER_COLLECTION = "User";

export const PAYMENTS_COLLECTION = "Payments";

export const VENUES_COLLECTION = "Venues";

export const RULES_COLLECTION = "Rules";

export const SERVICES_COLLECTION = "Capacities";

export const DIRECTORIES_COLLECTION = "Directories";

export const ACCOUNT_STATUS_COLLECTION = "AccountStatus";

export const RESOURCES_COLLECTION = "Resources";

export default {
  EVENTS_COLLECTION,
  DATES_COLLECTION,
  BOOKINGS_COLLECTION,
  ORGANISATIONS_COLLECTION,
  MEMBERS_COLLECTION,
  PROGRAMMES_COLLECTION,
  ORDERS_COLLECTION,
  PAYMENTS_COLLECTION,
  VENUES_COLLECTION,
  SERVICES_COLLECTION,
  DIRECTORIES_COLLECTION,
  ACCOUNT_STATUS_COLLECTION,
  RESOURCES_COLLECTION,
};
