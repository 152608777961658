/* eslint-disable import/prefer-default-export */
import { useState, useRef, useEffect } from "react";

export const SelectAndOther = (props) => {
  const {
    value,
    onChange,
    schemaObj,
    otherText,
    className,
    type,
    id,
    ...rest
  } = props;
  const otherOptionText = otherText || "Other, please define";

  const [selectValue, setSelectValue] = useState(
    schemaObj?.options?.includes(value) ? value : value ? otherOptionText : ""
  );

  const [manuallySelected, setManuallySelected] = useState(false);
  const showOther = selectValue && selectValue === otherOptionText;

  const otherRef = useRef(null);

  useEffect(() => {
    if (showOther && manuallySelected) {
      otherRef.current.focus();
    }
  }, [showOther]);

  let passedProps = { ...rest };
  if (showOther) {
    passedProps = {};
  }
  return (
    <>
      <select
        {...props}
        type="selectAndOther"
        value={selectValue || props.value}
        className={`${className} max-w-xs`}
        onChange={(e) => {
          setManuallySelected(true);
          setSelectValue(e.target.value);
          if (e.target.value !== otherOptionText) {
            if (onChange) {
              onChange(e);
            }
          } else {
            if (onChange) {
              onChange({ ...e, target: { ...e.target, id, value: "" } });
            }
          }
        }}
      >
        <option value="" disabled selected hidden></option>
        {schemaObj?.options?.length
          ? schemaObj?.options.map((option, index) => (
              <option key={option} value={option}>{option}</option>
            ))
          : null}
      </select>

      {showOther && (
        <div className={`${className} max-w-xs mt-3`}>
          <input
            {...rest}
            type="text"
            id={id}
            placeholder={`${rest?.placeholder} - please add`}
            value={value}
            name={props?.name}
            ref={otherRef}
            className={`${className} max-w-xs mt-3`}
            onChange={(e) => {
              onChange && onChange(e);
            }}
          />
        </div>
      )}
    </>
  );
};
