import React, { useState, useRef, useEffect } from "react";

const DateInput = ({ value, onChange, className, required }) => {
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  if (typeof value === "string") value = new Date(value);

  const [day, setDay] = useState(value ? value.getDate() : null);
  const [month, setMonth] = useState(value ? value.getMonth() + 1 : null);
  const [year, setYear] = useState(value ? value.getFullYear() : null);

  useEffect(() => {
    const { activeElement } = document;
    const dayInput = dayRef.current;
    const monthInput = monthRef.current;
    const yearInput = yearRef.current;

    // console.log({ day, month, year });
    // if day has been filled in, focus month
    if (
      day?.length === 2 &&
      activeElement !== monthInput &&
      activeElement !== yearInput
    ) {
      monthInput.focus();
    }
    // if month filled in, focus year
    if (
      day?.length === 2 &&
      month?.length === 2 &&
      activeElement !== yearInput &&
      activeElement !== dayInput
    ) {
      yearInput.focus();
    }
  }, [day, month, year]);

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  const [endOfMonth, setEndOfMonth] = useState(false);

  const [typing, setTyping] = useState(true);

  useEffect(() => {
    if (month) {
      let newMonth;
      let newYear;
      if (Number(month) > 12) {
        newMonth = Number(month) % 12;
        newYear = Number(year) + 1;
        setMonth(newMonth);
        setYear(newYear);
      }
      if (Number(month) < 1 && year && !typing) {
        newMonth = Number(month) + 12;
        newYear = Number(year) - 1;
        setMonth(newMonth);
        setYear(newYear);
      }
    }
  }, [month]);

  useEffect(() => {
    if (endOfMonth && daysInMonth(month, year) !== Number(day)) {
      setDay(daysInMonth(month, year));
    }
  }, [month]);

  useEffect(() => {
    if (daysInMonth(month, year) === Number(day)) {
      setEndOfMonth(true);
    } else {
      setEndOfMonth(false);
    }
  }, [day]);

  useEffect(() => {
    if (day) {
      const dayNo = Number(day);
      if (dayNo < 1 && !typing) {
        const newMonth = Number(month) - 1;
        const days = daysInMonth(newMonth, year);
        setDay(dayNo + days);
        setMonth(newMonth);
      }
      if (dayNo > daysInMonth(month, year)) {
        setDay(dayNo - daysInMonth(month, year));
        const newMonth = Number(month) + 1;
        setMonth(newMonth);
      }
    }
  }, [day]);

  useEffect(() => {
    const date = new Date(`${month}/${day}/${year}`);
    // an invalid date is a date object but getTime will return NaN
    if (!isNaN(date.getTime()) && (year.length === 4 || year > 1000)) {
      onChange(date);
    }
  }, [day, month, year]);

  return (
    <div className="flex">
      <input
        id="day"
        className={`${className} w-20 mr-2`}
        ref={dayRef}
        name="day"
        type="number"
        value={day}
        onKeyDown={(e) => {
          if (e.key === "0") {
            setTyping(true);
          } else {
            setTyping(false);
          }
        }}
        required={required}
        placeholder="dd"
        onChange={(e) => setDay(e.target.value)}
      />
      <input
        id="month"
        name="month"
        ref={monthRef}
        type="number"
        className={`${className} w-20 mr-2`}
        value={month}
        required={required}
        placeholder="mm"
        onKeyDown={(e) => {
          if (e.key === "0") {
            setTyping(true);
          } else {
            setTyping(false);
          }
        }}
        onChange={(e) => setMonth(e.target.value)}
      />
      <input
        id="year"
        ref={yearRef}
        name="year"
        placeholder="yyyy"
        type="number"
        className={`${className} w-32 mr-2`}
        value={year}
        required={required}
        onChange={(e) => setYear(e.target.value)}
      />

      {((day && day > 32) || (month && month > 12)) &&
        year &&
        year.length === 4 && <p className="text-orange-600">Invalid date</p>}
    </div>
  );
};

export default DateInput;
