import { SITE_URL } from "../config";

export const fetchSuggestedPostcodes = async (postcode) => {
  if (postcode.length > 8) {
    throw new Error("Postcode Invalid");
  } else if (postcode) {
    const response = await fetch(`https://app.${SITE_URL}/api/postcode-autocomplete?postcode=${postcode}`);
    const { postcodes } = await response.json();
    return postcodes;
  }
};

export const fetchSuggestedSchools = async (searchText) => {
  const res = await fetch(`https://app.${SITE_URL}/api/schools?search=${encodeURIComponent(searchText)}`);
  return res.json();
};

export const fetchSchoolFromId = async (id) => {
  if (id && typeof id === "string") {
    const response = await fetch(`https://app.${SITE_URL}/api/schools/${id}`);
    const data = await response.json();
    return data;
  }
};

export default {
  fetchSuggestedPostcodes,
  fetchSuggestedSchools,
  fetchSchoolFromId,
};
